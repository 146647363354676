import { UserService } from "../security/user.service";
import { AuthenticationService } from "../security/authentication.service";
import { Router } from "@angular/router";
import { firstValueFrom } from "rxjs";

export function BootstrapServiceFactory(
  userService: UserService,
  authService: AuthenticationService,
  router: Router,
) {
  return async () => {

    console.info('Bootstrap');

    const isAuthUser = await firstValueFrom(
      userService.authenticated()
    );

    if (isAuthUser) {
      router.navigate(['cabinet']);
      return;
    }

  }
}
