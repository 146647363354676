import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthenticationService } from './authentication.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  constructor(
    private authService: AuthenticationService
)
{
    //
}

intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const token = this.authService.token;
    const isTokenValid = this.authService.isTokenValid;

    if (isTokenValid) {
        request = request.clone({
            setHeaders: {
                Authorization: `Bearer ${token}`,
            }
        });
    }

    return next.handle(request);
}
}
