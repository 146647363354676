import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpResponse
} from '@angular/common/http';
import { catchError, Observable, of, throwError } from 'rxjs';
import { JsonResponseModel } from '../models/jsonResponse.model';
import { MessageService } from 'primeng/api';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(
    private messageService: MessageService
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((response: HttpErrorResponse) => {

        const responseStatus = response.status;

        if (response.status === 401) {

          if (response.error instanceof Object) {

            const apiAnswer = response.error as JsonResponseModel;

            this.messageService.add({
              severity:'error',
              summary: 'Authorization failure',
              detail: 'Invalid credential',
              life: 5000
            });

          }

          return of(new HttpResponse({
            body: response.error,
          }));

        }

        if (response.status >= 500) {

          this.messageService.add({
            severity:'error',
            summary: 'Internal Server Error',
            detail: 'The server encountered an unexpected condition that prevented it from fulfilling the request.',
            life: 5000
          });

        }

        return throwError(() => response);
      })
    );
  }
}
