import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { JsonResponseModel } from '../models/jsonResponse.model';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  private apiURL: string;
    private httpOptions: object;
    private apiVersion: 'v1' | 'v2' = 'v1';
    private apiPartition: 'warehouse' = 'warehouse';

    constructor(private httpClient: HttpClient) {
        this.apiURL = environment.apiUrl;
        this.httpOptions = {
            headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded'),
            responseType: 'json',
        };
    }

    protected actionURL(action: string): string {
        return (
          this.apiURL
          + this.apiVersion
          + '/'
          + this.apiPartition
          + (action === '' ? '' : '/' + action)).replace(/\/+$/, ''
        );
    }

    public get(action: string): Observable<JsonResponseModel> {
        return this.httpClient.get<JsonResponseModel>(this.actionURL(action), this.httpOptions);
    }

    public post(action: string, params: {[key: string]: string} | undefined): Observable<JsonResponseModel> {

      const body = new URLSearchParams();

      if (params !== undefined) {

          for (const key in params) {
              body.set(key, params[key]);
          }

      }

      return this.httpClient.post<JsonResponseModel>(this.actionURL(action), body.toString(), this.httpOptions);
    }
}
