import { Injectable } from '@angular/core';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  constructor() { }

    public get token(): string | null {
        return localStorage.getItem(environment.tokenStorageName);
    }

    public set token(value: string | null) {

      if (value === null || value === '') {
          return;
      }

      localStorage.setItem(environment.tokenStorageName, value);
    }

    public removeToken(): void {
      localStorage.removeItem(environment.tokenStorageName);
    }

    public get decodedToken(): JwtPayload | null {

      if (this.token === null) {
          return null;
      }

      return jwtDecode(this.token);

    }

    public get isTokenExpired(): boolean {

      if (this.decodedToken?.exp === undefined) {
          return true;
      }

      const now = new Date(Date.now()).toUTCString();
      const exp = new Date((this.decodedToken.exp * 1000)).toUTCString();

      return now > exp;

    }

    public get isTokenValid(): boolean {

      if (this.token === null) {
          return false;
      }

      if (this.decodedToken === null) {
          return false;
      }

      if (this.isTokenExpired) {
          return false;
      }

      return true;
    }
}
