import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, map, Observable, of } from 'rxjs';
import { UserModel } from '../models/user.model';
import { ApiService } from '../services/api.service';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private currentUserSubject: BehaviorSubject<UserModel | null>;

  constructor(
    private apiService: ApiService,
    private authService: AuthenticationService,
    private router: Router
  ) {
    this.currentUserSubject = new BehaviorSubject<UserModel | null>(null);
  }

  private subscribeCurrentUser(user: UserModel): void {
    this.currentUserSubject.next(user);
  }

  public unsubscribeCurrentUser(): void {

    this.currentUserSubject.next(null);
    this.authService.removeToken();

  }

  public get currentUser(): UserModel | null {
    return this.currentUserSubject.value;
  }

  public authenticated(): Observable<boolean> {

    const token = this.authService.token;

    if (!token) {
      return of(false);
    }

    return this.apiService.get('user/profile').pipe(
      map(response => {

        if (response?.status === 'success' && response.data?.['user']) {

          this.subscribeCurrentUser(response.data?.['user']);
          return true;
        }

        return false;

      }),
    );
  }
}
